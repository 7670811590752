// extracted by mini-css-extract-plugin
export var fadeheight = "statistics-module--fadeheight--HydTu";
export var fadein = "statistics-module--fadein--uFsp7";
export var hintro = "statistics-module--hintro--Qx9IM";
export var moveup = "statistics-module--moveup--7ctxQ";
export var pulse = "statistics-module--pulse--ofi3S";
export var rotate = "statistics-module--rotate--aG+ZD";
export var rotatealt = "statistics-module--rotatealt--0OrHQ";
export var rotatealtrev = "statistics-module--rotatealtrev--X5KBg";
export var rotaterev = "statistics-module--rotaterev--UN2al";
export var speed = "statistics-module--speed--yLPTC";
export var squeeze = "statistics-module--squeeze--rA+WL";
export var statistics = "statistics-module--statistics--VqtsQ";