import React from 'react'
import * as styles from './statistics.module.scss'

const Statistics = ({ props }) => {


    return (
        <section className={styles.statistics}>
            Test
        </section>
    )
}

export default Statistics