import React, { useContext, useEffect, useState } from 'react'
import { Link } from "gatsby"
import { myContext } from '../helper/context'
import * as styles from './featured.module.scss'

const Featured = ({ props }) => {
    const { isLoc } = useContext(myContext)
    const [nearbyCourses, setNearbyCourses] = useState([])
    const [featuredCourse, setFeaturedCourse] = useState([])
    const [recentlyViewed, setRecentlyViewed] = useState([])

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('recentlyViewed'))) {
            setRecentlyViewed(JSON.parse(localStorage.getItem('recentlyViewed')))
        }

        // get nearby locations - used site wide
        fetch(`https://data.golfip.com/wp-json/search/v1/search/?type=courses&lat=${isLoc.latitude}
&lng=${isLoc.longitude}`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                let nearbyCourses = []
                let featuredCourse = []

                resultData.forEach(item => {
                    nearbyCourses.push(item)
                })
                //featured
                //find and push
                featuredCourse.push(nearbyCourses[1])
                console.log(nearbyCourses)
                nearbyCourses.splice(1,1)
                setFeaturedCourse(featuredCourse)

                setNearbyCourses(nearbyCourses)
            }).catch((error) => {
                console.log(error)
            })
    }, [isLoc])

    return (
        <section className={styles.featured}> 
            <ul>
                {featuredCourse && featuredCourse.map(location => (
                    <li className={styles.featured__course} key={location.name}>
                        <Link to={`/courses/${location.slug}/`}>
                            <div className={styles.featured__image}>
                                {location.cloudflare && <img src={`https://data.golfip.com/cdn-cgi/imagedelivery/PYt1FRAR9QKRNk22qcI1qw/${location.cloudflare}/nearbylarge`} />}
                            </div>
                            <div className={styles.featured__content}>
                                <h3 dangerouslySetInnerHTML={{ __html: location.name }} />
                                {location.address}
                            </div>
                        </Link>
                    </li>
                ))}
                {recentlyViewed && recentlyViewed.map(location => (
                    <li className="" key={location.name}>
                        <Link to={`/courses/${location.slug}/`}>
                            <div className={styles.featured__image}>
                                {location.cloudflare && <img src={`https://data.golfip.com/cdn-cgi/imagedelivery/PYt1FRAR9QKRNk22qcI1qw/${location.cloudflare}/nearbylarge`} />}
                            </div>
                            <div className={styles.featured__content}>
                                <h3 dangerouslySetInnerHTML={{ __html: location.name }} />
                                {location.address}
                            </div>
                        </Link>
                    </li>
                ))}
                {nearbyCourses.map(location => (
                    <li className="" key={location.name}>
                        <Link to={`/courses/${location.slug}/`}>
                            <div className={styles.featured__image}>
                                {location.cloudflare && <img src={`https://data.golfip.com/cdn-cgi/imagedelivery/PYt1FRAR9QKRNk22qcI1qw/${location.cloudflare}/nearbylarge`} />}
                            </div>
                            <div className={styles.featured__content}>
                                <h3 dangerouslySetInnerHTML={{ __html: location.name }} />
                                {location.address}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default Featured