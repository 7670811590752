import React from "react"
import Header from '../components/header'
import Infobar from '../components/infobar'
import Search from '../components/search'
import Featured from '../components/featured'
import Nearby from '../components/nearby'
import Statistics from '../components/statistics'
import * as styles from './index.module.scss'
import { SEO } from "../components/seo"

const IndexPage = () => {

  return (
    <div>
      <Header />
      <Infobar />
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.search}>
            <Search />
          </div>
          <div className={styles.featured}>
            <Featured />
          </div>
        </div>
        <div className={styles.nearby}>
          <Nearby />
        </div>
        <div className={styles.ad}>
          <div className={styles.ad__inner}></div>
        </div>
      </div>
      <Statistics />
    </div>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)