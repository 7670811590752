import React from 'react'
import * as styles from './infobar.module.scss'

const Infobar = ({ props }) => {

    return (
        <section className={styles.infobar}>
            <div className={styles.infobar__container}>
                <div className={styles.infobar__item}>
                    <div className={styles.infobar__item__name}>
                        Item One
                    </div>
                    <div className={styles.infobar__item__content}>
                        Content
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Infobar