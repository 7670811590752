import React, {useContext} from 'react'
import { Link } from "gatsby"
import { myContext } from '../helper/context'
import * as styles from './nearby.module.scss'

const Nearby = ({props}) => {
    const {isNearby} = useContext(myContext)

    return(

        <div className={styles.nearby}>
            <ul className={styles.nearby__list}>
            {isNearby && isNearby.map(location => (
                <li className="" key={location.name}>
                <Link to={`${location.slug}/`}>
                    <div className={styles.nearby__list__content}>
                        <h3>{location.name}</h3>
                    </div>
                    {location.cloudflare && <img src={`https://data.golfip.com/cdn-cgi/imagedelivery/PYt1FRAR9QKRNk22qcI1qw/${location.cloudflare}/nearbylarge`} alt={location.name} />}
                </Link>
            </li>
            ))}
            </ul>
        </div>
    )
}

export default Nearby