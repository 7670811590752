import React, { useContext, useState, useEffect } from "react"
import { myContext } from '../helper/context';
import { navigate, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from './search.module.scss'

const SearchBox = () => {
    const { isNearby } = useContext(myContext)
    const [findme, setFindme] = useState('')
    const [location, setLocation] = useState('')
    const [autoComplete, setAutoComplete] = useState([])
    const [showAutoComplete, setShowAutoComplete] = useState(false)

    const { changeLoc } = useContext(myContext);

    const handleSubmit = (e) => {
        e.preventDefault()

        if (autoComplete.length) {
            setLocation(autoComplete[0].text)

            navigate(`/search/?lat=${autoComplete[0].center[1]}&lng=${autoComplete[0].center[0]}`, { state: { foo: 'bar' } })
        } else {
            getLocation()
        }
    }

    const getLocation = (e) => {
        if (navigator.geolocation) {
            setFindme("Working...")
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        console.log(position)

        const testArray = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            city: "working"
        }

        changeLoc(testArray)
        navigate(`/search/?lat=${position.coords.latitude}&lng=${position.coords.longitude}`, { state: { foo: 'bar' } })
    }

    useEffect(() => {
        const interval = setTimeout(() => {
            if (location.length > 3) {
                console.log("trigger")
                fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?country=gb%2Cus&proximity=ip&types=region%2Cneighborhood%2Cdistrict%2Cpoi%2Clocality%2Cplace&access_token=pk.eyJ1IjoiZGFjaGFnZyIsImEiOiJjbDZlMnplZHMwYjAxM21teGx5eHFyanhiIn0.FsNLgXIbR7zsJOlkpqFjOA`)
                    .then((data) => data.json())
                    .then((data) => {
                        console.log(data)
                        setAutoComplete(data.features)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                setAutoComplete([])
                console.log("nada")
            }
        }, 500)
        return () => {
            console.log("reset")
            clearTimeout(interval);
        }
    }, [location]);

    return (
        <main className={styles.search}>
            <div className={styles.search__content}>
                <h1>Find <span>Courses</span>.</h1>
                <form className="autosearch" onSubmit={handleSubmit}>
                    <div className={styles.location_input}>
                        <input className="location-name" value={location} placeholder="Search by Location.." onFocus={event => setShowAutoComplete(true)} onChange={event => setLocation(event.target.value)} type="text" />
                    </div>
                    <input type="submit" value="Search" />
                </form>
                {showAutoComplete && (<ul className={styles.search__autofill}>

                    <li onClick={getLocation}>Near me location {findme}</li>
                    {autoComplete && autoComplete.map(location => (
                        <li className="" key={location.id}>
                            <Link to={`/search/?lat=${location.center[1]}&lng=${location.center[0]}`}>
                                {location.text}
                                <span>{location.place_name}</span>
                            </Link>
                        </li>
                    ))}

                    {isNearby && isNearby.map(location => (
                        <li className="" key={location.name}>
                            <Link to={`${location.slug}/`}>
                                {location.name}
                                <span>{location.address}</span>
                            </Link>
                        </li>
                    ))}
                </ul>)
                }
            </div>
            <StaticImage 
                src="../images/pebble.webp" 
                alt="Mini Golf"
                className={styles.search__background}
                width="1000"
            />
        </main>
    )
}

export default SearchBox