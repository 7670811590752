// extracted by mini-css-extract-plugin
export var fadeheight = "featured-module--fadeheight--qRxez";
export var fadein = "featured-module--fadein--TzbrY";
export var featured = "featured-module--featured--uPHrT";
export var featured__content = "featured-module--featured__content--nzWe2";
export var featured__course = "featured-module--featured__course--VCLkT";
export var featured__image = "featured-module--featured__image--QUJrj";
export var hintro = "featured-module--hintro--ANWUU";
export var moveup = "featured-module--moveup--LiPVQ";
export var pulse = "featured-module--pulse--S9lx4";
export var rotate = "featured-module--rotate--sRn4P";
export var rotatealt = "featured-module--rotatealt--sV+Ge";
export var rotatealtrev = "featured-module--rotatealtrev--rbQ+T";
export var rotaterev = "featured-module--rotaterev--8eyqS";
export var speed = "featured-module--speed--P1N5l";
export var squeeze = "featured-module--squeeze--bT4me";