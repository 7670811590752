// extracted by mini-css-extract-plugin
export var fadeheight = "nearby-module--fadeheight--PqGlT";
export var fadein = "nearby-module--fadein--gAZe1";
export var hintro = "nearby-module--hintro--xhpjP";
export var moveup = "nearby-module--moveup--DAt5u";
export var nearby = "nearby-module--nearby--M81Ib";
export var nearby__list = "nearby-module--nearby__list--eMOoD";
export var nearby__list__content = "nearby-module--nearby__list__content--Z2Khl";
export var pulse = "nearby-module--pulse--iUKdC";
export var rotate = "nearby-module--rotate--Ieoif";
export var rotatealt = "nearby-module--rotatealt--a2nPg";
export var rotatealtrev = "nearby-module--rotatealtrev--o-KdH";
export var rotaterev = "nearby-module--rotaterev--mMWiH";
export var speed = "nearby-module--speed--NOKQo";
export var squeeze = "nearby-module--squeeze--GuK5Z";